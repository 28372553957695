<template>
  <div class="tile is-vcentered">
    <figure class="image">
      <a :href="this.link">
        <img
          :src="require(`@/assets/logos/${this.name}.png`)"
          :alt="this.alt"
        />
      </a>
    </figure>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    name: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.is-vcentered {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /*   display: flex;
  flex-wrap: wrap;
  align-content: center; / * used this for multiple child * /
  align-items: center; / * if an only child * / 
  */
}
</style>
