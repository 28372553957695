<template>
  <div class="columns">
    <div class="column is-4 content">
      <h1 class="title is-1">About this site</h1>
    </div>
    <div class="column is-8 content">
      <p class="subtitle">
        Disclaimer. This site is still under development and probably always
        will be.
      </p>
      <p class="subtitle">
        This static web site was built with bulma and vue.js. It runs on
        docker.io behind a reverse proxy server, traefik. Deployment is made by
        drone.io from a self hosted git repository. We are using letsencrypt in
        an automated way for https encryption.
      </p>
      <div class="tile is-parent">
        <Logo name="bulma" link="https://bulma.io" alt="bulma" />
        <Logo name="vuejs" link="https://vuejs.org" alt="vue.js" />
        <Logo name="gitea" link="https://gitea.io" alt="gitea" />
        <Logo name="docker" link="https://docker.io" alt="docker" />
      </div>
      <p class="subtitle">
        Other technologies that have played a role in bringing this site up are
        saltstack as a
        <abbr lang="en" title="Configuration Management System">CMS</abbr>,
        python and golang, terraform, cloud-init and packer.
      </p>
      <div class="tile is-parent">
        <Logo name="saltstack" link="https://saltstack.com" alt="saltstack" />
        <Logo name="python" link="https://python.org" alt="python" />
        <Logo name="golang" link="https://golang.org" alt="golang" />
        <Logo name="terraform" link="https://terraform.io" alt="terraform" />
      </div>
      <p class="subtitle">
        The code was developed on a linux machine running endeavour os using
        vscode. Although I also very much like vim, spacemacs and pycharm,
        vscode is my weapon of choice at the moment. All of them, apart from vim
        itself, run a vim emulation anyway.
      </p>
      <div class="tile is-ancestor">
        <Logo name="vim" link="https://www.vim.org" alt="vim" />
        <Logo
          name="spacemacs"
          link="https://www.spacemacs.org"
          alt="spacemacs"
        />
        <Logo name="pycharm" link="https://terraform.io" alt="pycharm" />
        <Logo
          name="vscode"
          link="https://code.visualstudio.com/"
          alt="vscode"
        />
      </div>
      <p class="subtitle">
        In order to handle all those tools, a decent amount of knowledge in the
        domains of IP, networking, firewalling, DNS, DHCP and HTTP is required.
        Preferably storage and virtualization as well.
      </p>
    </div>
  </div>
</template>
<script>
import Logo from "@/components/Logo.vue";
export default {
  name: "About",
  components: {
    Logo,
  },
};
</script>
<style lang="scss" scoped>
.tile {
  margin: 1.5em 0;
}
</style>
